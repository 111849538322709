import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DataSharedService {

	private isLoadCategory = new BehaviorSubject<boolean>(false);

	onLoadCategory = this.isLoadCategory.asObservable();
    
	constructor() { }

	onCategoryLoad(isLoader: boolean = false) {
		this.isLoadCategory.next(isLoader);
		// this.isLoadCategory.complete();
	}

}