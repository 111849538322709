import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-puzzles',
  templateUrl: './puzzles.page.html',
  styleUrls: ['./puzzles.page.scss'],
})
export class PuzzlesPage implements OnInit {

  puzzleTitle: string;
  public isMenuOpen : boolean = false;
  constructor() { }

  ngOnInit() {
    this.puzzleTitle = "Which number replaces the question mark in the picture";
  }
  public toggleAccordion() : void
  {
      this.isMenuOpen = !this.isMenuOpen;
  }
}
