import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { Config } from "src/config/Config";
import { BaseService } from "../../base.service";


@Injectable()
export class QuestionManager extends BaseService {

    findSubCategories(categoryId: any, secondCategories: any, thirdCategories: any) {
        let data: any = {};
        data['categoryId'] = categoryId;
        data['secondCategories'] = secondCategories;
        data['thirdCategories'] = thirdCategories;
        console.log("thirdCategories", thirdCategories);

        return this.getCallService(Config.BASE_URL + Config.FIND_SUB_CATEGORIES, data).pipe(map(res => {
            return res;
        }))
    }

    question(secondCategories: any, thirdCategories: any) {
        let data: any = {};
        data['secondCategories'] = secondCategories;
        data['thirdCategories'] = thirdCategories;
        return this.getCallService(Config.BASE_URL + Config.FIND_QUESTIONS, data).pipe(map(res => {
            return res;
        }))
    }

}