import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { NgbAccordionModule, NgbCollapseModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { NgbCollapseModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TruncatePipe } from './commoncomponent';
import { AccordionModule } from './components/accordion/accordion.module';
// import { AccordionComponent } from './components/accordion/accordion.component';
// import { AccordionModule } from './components/accordion/accordion.module';
// import { BannerPage } from './banner/banner.page';
import { SetOnePageModule } from './components/quiz-module/quizlets/set-one/set-one.module';
import { HomePageRoutingModule } from './home/home-routing.module';
import { CalloutService } from './services/callout.service';
import { CalloutComponent } from './services/callout/callout.component';
import { DataSharedService } from './services/datashared.service';
import { LoginManager } from './services/restcontroller/bizservice/login.service';
import { QuestionManager } from './services/restcontroller/bizservice/question.service';
import { QuizManager } from './services/restcontroller/bizservice/quiz.service';
import { PopupComponent } from './shared/popup/popup.component';
import { JwtInterceptor } from './_helpers';
import { ErrorInterceptor } from './_helpers/error.interceptor';
// import { httpsInterceptor } from './_helpers/http.interceptor';


@NgModule({
  declarations: [
    AppComponent,
    CalloutComponent,
    PopupComponent,
    // BannerPage
    // TruncatePipe
    

  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    SetOnePageModule,
    HomePageRoutingModule,
    NgbModule,
    MatTabsModule,
    MatTooltipModule,
    AccordionModule,
    
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000',

    }),

  ],
  providers: [CalloutService, LoginManager, DataSharedService, QuestionManager, QuizManager,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Location, { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: httpsInterceptor, multi: true }  
    // { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  exports: [PopupComponent,NgbCollapseModule,],
  // providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],

  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
