import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SetFourPage } from '../components/quiz-module/quizlets/set-four/set-four.page';
import { SetThreePage } from '../components/quiz-module/quizlets/set-three/set-three.page';
import { SetTwoPage } from '../components/quiz-module/quizlets/set-two/set-two.page';
import { PuzzlesPage } from '../puzzles/puzzles.page';
import { HomePage } from './home.page';

const routes: Routes = [
  {
    path: '',
    component: HomePage,
  },
  {
    path: 'set-two',
    component: SetTwoPage,
  },
  {
    path: 'set-three',
    component: SetThreePage,
  },
  {
    path: 'set-four',
    component: SetFourPage,
  },
  {
    path: 'puzzles',
    component: PuzzlesPage,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomePageRoutingModule {}
