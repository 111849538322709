import { DoCheck, Input } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Renderer2 } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BackendService } from 'src/app/services/backend.service'

@Component({
  selector: 'app-set-two',
  templateUrl: './set-two.page.html',
  styleUrls: ['./set-two.page.scss'],
})
export class SetTwoPage implements OnInit {
  username: string;
  userid: any;
  playerName: string;
  gameId = 2;
  isQuestionCardShow: boolean = false;
  @ViewChild('questionTest') questionTest: any;
  userAnswer: any;
  correctAnswer: any;
  submitModal: boolean = false;
  allQuestions: any[];
  scoreBoard: any = {};
  currentUser: any = {};
  @Input() questionIndex: number;
  currentQuestion: string;
  currentOptions: string;
  currentIndex = 0;
  score = 0;
  disableSubmit: boolean = true;
  disableNextBtn: boolean = true;
  counter = 15;
  lap = 0;
  arr: any;
  startTime: number;
  endTime: number;
  totalTime: number;
  totalTimeTaken: any;
  sec: any;
  rightOption: any;
  currentImage: any;
  error: string;
  url: any;
  quizCategory: any;
  scoreColor: any;
  scoreProgress = 0;
  userGlobalId = 0;
  _subscription_user_id: any;

  constructor(
    private router: Router, 
    private renderer: Renderer2, 
    private http: HttpClient,
    private userIdService: BackendService
    ) {
      this._subscription_user_id = this.userIdService.execChange.subscribe((value) => {
      this.userid = value;
    });
    }

  ngOnInit(): void {
    this.username = this.readLocalStorageValue('username');
    this.userid = this.readGuestLocalStorageValue('userid');
    this.allQuestions = [
      {
        question: 'What is the currency of Kazakhstan?',
        options: ['Rupee', 'Rupiah', 'Tenge', 'Dinar'],
        answer: 'Tenge'
      },
      {
        question: 'What is the currency of Brazil?',
        options: ['Dollar', 'Yen', 'Pound', 'Real'],
        answer: 'Dollar'
      },           
      {
        question: 'DRACHMA symbol of currency was used in which European country?',
        options: ['Greece', 'Italy', 'Germany','France'],
        answer: 'Greece'
      },
      {
        question: "What is the currency of Thailand?",
        options: ['Dinar', 'Dollar', 'Delhi', 'Baht'],
        answer: 'Baht'
      },
      {
        question: "Which of the following planets is called Dwarf Planet?",
        options: ['Mercury', 'Pluto', 'Mars', 'Uranus'],
        answer: 'Pluto'
      },
      {
        question: "What is the currency of United States?",
        options: ['Dollar', 'Yen', 'Pound', 'Dinar'],
        answer: 'Dollar'
      }, 
      {
        question: "Study of the Universe is called:",
        options: ['Sociology', 'Cosmology', 'Universology', 'Petology'],
        answer: 'Cosmology'
      }, 
      {
        question: 'What is the currency of Germany?',
        options: ['Pound', 'Euro', 'Dollar', 'Riyan'],
        answer: 'Euro'
      },   
      {
        question: "What is the currency of Sweden?",
        options: ['Dollar', 'Swedish Krona', 'Yen', 'Pound'],
        answer: 'Swedish Krona'
      },
      {
        question: 'What is the currency of Kyrgyzstan?',
        options: ['Tenge', 'Som', 'Dinar', 'Ringgit'],
        answer: 'Som'
      },
      {
        question: "What is the currency of Spain?",
        options: ['Dollar', 'Euro', 'Yen', 'Pound'],
        answer: 'Euro'
      },
      {
        question: 'When did the Big Bang explosion occur?',
        options: ['10 Billion years ago', '15 Billion years ago', '20 Billion years ago', '25 Billion years ago'],
        answer: '15 Billion years ago'
      },
      {
        question: 'What is the currency of Greece?',
        options: ['Dollar', 'Euro', 'Yen', 'Pound'],
        answer: 'Euro'
      },
      {
        question: 'Ringgit is the currency of:',
        options: ['Brunie', 'Bahrain', 'Cyprus', 'Jordan'],
        answer: 'Jordan'
      },
      {
        question: "What is the currency of Nepal?",
        options: ['Rupee', 'Nepalese Rupee', 'Rupiah', 'Taka'],
        answer: 'Rupiah'
      },      
      {
        question: '',
        options: ['a', 'b', 'c', 'd'],
        answer: 'c'
      },
    ];
  }

  readLocalStorageValue(key: string): string {
    return localStorage.getItem(key);
  }
  readGuestLocalStorageValue(key: string): string {
    return localStorage.getItem(key);
  }
  ngDoCheck(): void {
    this.currentQuestion = this.allQuestions[this.currentIndex].question;
    this.currentOptions = this.allQuestions[this.currentIndex].options;
  }

  startQuiz() {
    this.quizCategory = "Set Two";
    this.validation();
  }

  validation() {
    if(!this.username) {
      if (this.playerName == null) {
        this.error = "Please enter your name";
      } else {
        this.questionTest.reset();
        this.isQuestionCardShow = true;
        this.startTimer();
        this.startTime = performance.now();
      }
    } else {
      this.playerName = this.username;
      this.questionTest.reset();
      this.isQuestionCardShow = true;
      this.startTimer();
      this.startTime = performance.now();
    }    
  }

  checkprogress() {
    this.scoreProgress = this.score / (this.allQuestions.length-1);
  }

  setUserAnswer(option: string) {
    this.userAnswer = option;
    this.correctAnswer = this.allQuestions[this.currentIndex].answer;

    this.arr = document.getElementsByClassName('answers');
    for (let i = 0; i < this.arr.length; i++) {
      if (this.arr[i].value == this.correctAnswer) {
        this.rightOption = this.arr[i];
      }
    }

    if (this.userAnswer === this.correctAnswer) {
      this.renderer.addClass(event.target, "rightAns");
      this.score++;
      this.playRightAudio();

      for (let i = 0; i < this.arr.length; i++) {
        this.arr[i].disabled = true;
      }
    } else {
      this.renderer.addClass(event.target, "wrongAns");
      this.renderer.addClass(this.rightOption, "rightAns");
      this.playWrongAudio();

      for (let i = 0; i < this.arr.length; i++) {
        this.arr[i].disabled = true;
      }
    }
    this.stopTimer();

    setTimeout(() => {
      this.goNext();
    }, 2000);
  }

  goNext() {
    this.currentIndex++;
    if (this.currentIndex === this.allQuestions.length - 1) {
      this.endQuiz();
    }
  }

  volumeIcon: string = 'volume-high';
  muteSound() {
    this.volumeIcon = this.volumeIcon === 'volume-high' ? 'volume-mute' : 'volume-high';
  }

  playRightAudio() {
    let audio = new Audio();
    audio.src = "../../../assets/audio/Correct-answer.mp3";
    audio.load();
    audio.play();
    if(this.volumeIcon === 'volume-mute') {
      audio.muted = true;
    }
  }

  playWrongAudio() {
    let audio = new Audio();
    audio.src = "../../../assets/audio/Wrong-answer.mp3";
    audio.load();
    audio.play();
    if(this.volumeIcon === 'volume-mute') {
      audio.muted = true;
    }
  }

  endQuiz() {
    clearInterval(this.interval);
    this.endTime = performance.now();
    this.convertTime();
    this.checkprogress();
    this.storeUserData();
    setTimeout(() => {
      this.getRankData();
      this.getCurrentData();
     
    }, 2000); 
    this.isQuestionCardShow = false;
    this.submitModal = true; 
  }

  storeUserData() {
    var percentage = (this.score/this.allQuestions.length)*100;
    const formData = new FormData();
    formData.append('playerName', this.playerName);
    formData.append('score', this.score.toString());
    formData.append('endTime', this.totalTimeTaken.toString());
    formData.append('scorePercentage', percentage.toString());
    formData.append('game_id', this.gameId.toString());

    this.http.post<any>("https://quiz.thinkmad.in/thinkmad/welcome/insert_record/", formData).subscribe( data => {
      localStorage.setItem('userid', data.data.user_id);
      this.userIdService.userIdChange(data.data.user_id);
    });  
  }

  getRankData() {
    this.http.get('https://quiz.thinkmad.in/thinkmad/welcome/record_list/2/1').subscribe(data => {
      this.scoreBoard.data = [];
      this.scoreBoard = data;
    }, error => console.error(error));
  }
 
  getCurrentData() {
    this.http.get('https://quiz.thinkmad.in/thinkmad/welcome/record_list/2/'+this.userid).subscribe(data => {
      this.currentUser.data = [];
      this.currentUser = data;
    }, error => console.error(error));
  }

  convertTime() {
    this.totalTime = this.endTime - this.startTime;

    var min = this.totalTime / 1000 / 60;
    var r = min % 1;
    this.sec = Math.floor(r * 60);
    if (this.sec < 10) {
      this.sec = '0' + this.sec;
    }
    min = Math.floor(min);
    this.totalTimeTaken = '00:0' + min + ':' + this.sec;
    return this.totalTimeTaken;
  }

  closeSubmitModal() {
    this.submitModal = false;
    this.router.navigateByUrl('home');
    this.questionTest.reset();
  }

  timeLeft: number = 15;
  interval;

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
        if (this.timeLeft == 0) {
          setTimeout(() => {
            this.goNext();
          }, 2000);
          setTimeout(() => {
            this.stopTimer();
          }, 1000);
        }
      }
    }, 1000);
  }

  stopTimer() {
    clearInterval(this.interval);
    setTimeout(() => {
      this.timeLeft = 15;
      this.startTimer();
    }, 2000);
  }
}
