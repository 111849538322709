import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { Config } from "src/config/Config";
import { BaseService } from "../../base.service";


@Injectable()
export class LoginManager extends BaseService {

    login(formData) {
        return this.postCallService(Config.BASE_URL + Config.LOGIN_URL, {}, formData).pipe(map(res => {
            if (res) {
                sessionStorage.setItem('currentUser', JSON.stringify(res));
            }
            return res;
        }))
    }
    public get getcurrentUser() {
        let currentUser = JSON.parse(sessionStorage.getItem('currentUser') || '{}');
        console.log("currentUser", currentUser);
        // this.currentUserSubject.next(currentUser.userDTO);
        return currentUser.username;
    }
    public get getcurrentId() {
        let currentUser = JSON.parse(sessionStorage.getItem('currentUser') || '{}');
        console.log("currentUser", currentUser);
        // this.currentUserSubject.next(currentUser.userDTO);
        return currentUser._id;
    }
    logout(temp: any) {
        console.log("logout--->called");
        sessionStorage.removeItem('currentUser');
        sessionStorage.clear();
        window.sessionStorage.clear();
        this.currentUserSubject.next(temp);
        // this.router.navigate(['']);
    }
}
