import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  username: string;
  currentUser:string;
  constructor() { }
 

  ngOnInit() {

    this.username = this.readLocalStorageValue('username');

  }

  readLocalStorageValue(key: string): string {
    return localStorage.getItem(key);
  }


  logout() {
    sessionStorage.removeItem('currentUser');
    //  this.dataSharedService.changeMenuAction(null);
    // this.currentUserSubject.next(temp);
    // this.router.navigate(['']);
  }
}
