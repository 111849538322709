import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BackendService {
  execChange: Subject<any> = new Subject<any>();
  constructor() { }

  userIdChange(data: any) {
    this.execChange.next(data);
  }
}
