import { HttpClient } from '@angular/common/http';
import { Component, DoCheck, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular';
import * as _ from 'lodash';
import { BackendService } from 'src/app/services/backend.service';
import { LoginManager } from 'src/app/services/restcontroller/bizservice/login.service';
import { QuestionManager } from 'src/app/services/restcontroller/bizservice/question.service';
import { QuizManager } from 'src/app/services/restcontroller/bizservice/quiz.service';
import { Quiz } from 'src/app/services/restcontroller/models/quiz';
import { Config } from 'src/config/Config';
// import { IonAccordionGroup } from '@ionic/angular';
@Component({
	selector: 'app-set-one',
	templateUrl: './set-one.page.html',
	styleUrls: ['./set-one.page.scss'],
})
export class SetOnePage implements OnInit, DoCheck {
	private accordion_expanded = false;
	// @ViewChild(IonAccordionGroup, { static: true }) accordionGroup: IonAccordionGroup;
	username: any;
	userid: any;
	playerName: string;
	idQuiz = 1;
	isQuestionCardShow: boolean = false;
	@ViewChild('questionTest') questionTest: any;
	userAnswer: any;
	correctAnswer: any;
	submitModal: boolean = false;
	allQuestions: any[] = [];
	scoreBoard: any = {};
	currentUser: any = {};
	@Input() questionIndex: number;
	currentQuestion: string;
	currentOptions: string;
	currentIndex = 0;
	score = 0;
	disableSubmit: boolean = true;
	disableNextBtn: boolean = true;
	counter = 15;
	lap = 0;
	arr: any;
	startTime: number;
	elapsedTime: any;
	totalTime: number;
	totalTimeTaken: any;
	sec: any;
	rightOption: any;
	currentImage: any;
	error: string;
	url: any;
	currentIndexi = 0;
	currentindexj = 0;
	quizCategory: string = "";
	scoreColor: any;
	scoreProgress = 0;
	userGlobalId = 0;
	chunkSize: any;
	_subscription_user_id: any;
	thirdCategories: any;
	totalSetCount = 0;
	quiz: string = "Quiz"
	secondCategories: any;
	categoryId: any;
	seccategories: string = "Aptitude";
	thirdcategories: string = "Problems on Trains";
	setOne: string = "set-1";
	setTwo: string = "set-2";
	setThree: string = "set-3";
	setFour: string = "set-4";
	setFive: string = "set-5";
	quizTitle: string = "";
	Currentuser: any;
	rank: any;
	gaugeType = "arch";
	gaugeValue = 58.3;
	totalQues: any;
	CategoryList: any;
	selectedName: any
	public columns: any;
	user: boolean = false;
	currentusername: any;
	private _id: any;
	constructor(
		private router: Router,
		private renderer: Renderer2,
		private http: HttpClient,
		private userIdService: BackendService, private route: ActivatedRoute, private questionManager: QuestionManager, private quizManager: QuizManager, private loginManager: LoginManager, public alertController: AlertController, public navCtrl: NavController
	) {
		this._subscription_user_id = this.userIdService.execChange.subscribe((value) => {
			this.userid = value;
		});
		this.columns = [
			{
				name: 'Name', resizeable: false, sortable: false, width: 50,
				category: 'Category'
			},
		];
	}
	questionset: any = [];
	// public highlightRow(emp) {
	// 	this.selectedName = emp.username;
	//   }
	ngOnInit(): void {
		this.username = this.loginManager.getcurrentUser;
		this._id = this.loginManager.getcurrentId;
		// this.username = this.readLocalStorageValue('username');
		// console.log("username", this.username);

		this.getCategories();

		this.allQuestions = [];
		let allQuestionList: any[] = [];
		this.route.queryParams.subscribe(params => {
			this.categoryId = params.categoryId;
			this.secondCategories = params.sub1Id;
			this.thirdCategories = params.sub2Id;
			this.questionManager.findSubCategories(this.categoryId, this.secondCategories, this.thirdCategories).subscribe((res => {
				console.log("res", res);
				this.quizTitle = res;

			}));

			this.questionManager.question(this.secondCategories, this.thirdCategories).subscribe((res => {
				console.log('res-->question', res)
				this.totalQues = res.length;
				let allQuestionsList = res;
				for (let quest of allQuestionsList) {
					let question: any = {};
					question.question = quest.question;
					let options: any[] = [];
					for (let opt of quest.options) {
						options.push(opt.option);
					}
					question.options = options;
					question.answer = quest.answer;
					allQuestionList.push(question);
				}
				this.questionset = _.chunk(allQuestionList, 15);
				this.totalSetCount = this.questionset.length;
				this.allQuestions = this.questionset[0];
			}));
		});
		this.quizManager.find().subscribe(res => {
			console.log("res", res);
			this.Currentuser = res;

		})

	}

	getCategories() {
		this.http.get<any>(Config.BASE_URL + Config.GET_CATEGORIES).subscribe((res) => {
			console.log("res---->get", res)
			// console.log("res", res);
			this.CategoryList = res;
			this.CategoryList.splice(0, 1);
			// this.showCategeories(this.CategoryList[0]);
		}
		)
	}

	readLocalStorageValue(key: string): string {
		return localStorage.getItem(key);
	}

	readGuestLocalStorageValue(key: string): string {
		return localStorage.getItem(key);
	}
	ngDoCheck(): void {
		if (this.allQuestions && this.allQuestions.length > 0 && this.currentIndex < this.allQuestions.length) {
			this.currentQuestion = this.allQuestions[this.currentIndex].question;
			this.currentOptions = this.allQuestions[this.currentIndex].options;
		}
	}

	startQuiz() {
		// for(let i=1; i<this.totalSetCount;i++){
		// 	console.log("teatttt")
		// 	this.quizCategory=this.quizCategory+"set- " + i;
		// }
		this.validation();
		this.user = true;
	}

	validation() {
		if (this.username) {
			if (this.username == null) {
				this.error = "Please enter your name";
			}
			else {
				this.questionTest.reset();
				this.isQuestionCardShow = true;
				this.startTimer();
				this.startTime = performance.now();
			}
		}
		else {
			// this.username = this.username;
			this.questionTest.reset();
			this.isQuestionCardShow = true;
			this.startTimer();
			this.startTime = performance.now();
		}
	}

	checkprogress() {
		this.scoreProgress = this.score / (this.allQuestions.length);
	}

	setUserAnswer(option: string) {
		this.userAnswer = option;
		this.correctAnswer = this.allQuestions[this.currentIndex].answer;

		this.arr = document.getElementsByClassName('answers');
		for (let i = 0; i < this.arr.length; i++) {
			if (this.arr[i].value == this.correctAnswer) {
				this.rightOption = this.arr[i];
			}
		}

		if (this.userAnswer === this.correctAnswer) {
			this.renderer.addClass(event.target, "rightAns");
			this.score++;
			this.playRightAudio();

			for (let i = 0; i < this.arr.length; i++) {
				this.arr[i].disabled = true;
			}
		} else {
			this.renderer.addClass(event.target, "wrongAns");
			this.renderer.addClass(this.rightOption, "rightAns");
			this.playWrongAudio();

			for (let i = 0; i < this.arr.length; i++) {
				this.arr[i].disabled = true;
			}
		}
		this.stopTimer();
		setTimeout(() => {
			this.goNext();
		}, 2000);
	}

	goNext() {
		this.currentIndex++;
		if (this.currentIndex === this.allQuestions.length) {
			// this.currentIndex = 0;
			// this.currentIndexi++;
			// if (this.currentIndexi == this.totalSetCount) {
			this.endQuiz();
			// } else {
			// 	this.allQuestions = this.questionset[this.currentIndexi];
			// }

		}
	}

	onSetClick(event: any, currentSetIndex: any) {
		this.currentIndex = 0;
		this.currentIndexi = currentSetIndex;
		this.allQuestions = this.questionset[this.currentIndexi];
	}

	volumeIcon: string = 'volume-high';
	muteSound() {
		this.volumeIcon = this.volumeIcon === 'volume-high' ? 'volume-mute' : 'volume-high';
	}

	playRightAudio() {
		let audio = new Audio();
		audio.src = "../../../assets/audio/Correct-answer.mp3";
		audio.load();
		audio.play();
		if (this.volumeIcon === 'volume-mute') {
			audio.muted = true;
		}
	}

	playWrongAudio() {
		let audio = new Audio();
		audio.src = "../../../assets/audio/Wrong-answer.mp3";
		audio.load();
		audio.play();
		if (this.volumeIcon === 'volume-mute') {
			audio.muted = true;
		}
	}

	endQuiz() {
		clearInterval(this.interval);
		this.elapsedTime = performance.now();
		this.convertTime();
		this.checkprogress();
		this.storeUserData();
		// setTimeout(() => {
		// this.getRankData();
		// this.getCurrentData();
		// }, 2000);
		this.isQuestionCardShow = false;
		this.submitModal = true;
	}

	storeUserData() {
		// var percentage = (this.score / this.allQuestions.length) * 100;
		// console.log("this.username", this.username);
		// // let formData = new FormData();
		// formData.append('playerName', this.username);
		// formData.append('score', this.score.toString());
		// formData.append('endTime', this.totalTimeTaken.toString());
		// formData.append('scorePercentage', percentage.toString());
		// formData.append('game_id', this.idQuiz.toString());
		// console.log(" this.playerName", this.username);
		// console.log("score", this.score.toString());
		// console.log(" endTime", this.totalTimeTaken.toString());
		// console.log(" scorePercentage", percentage.toString());
		// console.log(" game_id", this.idQuiz.toString());
		// https://quiz.thinkmad.in/thinkmad/welcome/insert_record/1
		// this.http.post<any>(Config.BASE_URL + Config.SIGNUP_URL)

		let quiz = new Quiz();
		quiz.username = this.username;
		quiz.score = this.score;
		quiz.elapsedTime = this.sec;
		quiz.total_questions = this.totalQues;
		quiz.complete = true;
		console.log("quizz", quiz);

		this.quizManager.insert(quiz).subscribe((res => {
			console.log("Currentuser----->set", res);
			this.Currentuser = res;
			this.ngOnInit();
		}));


		// 	this.http.post<any>(Config.BASE_URL + 'quizss',formData).subscribe(data => {
		// 		console.log("dataa--1", data);
		// 		this.username=data;
		// 		// localStorage.setItem('userid', data.data.user_id);
		// 		// this.userIdService.userIdChange(data.data.user_id);
		// 	} ,error => console.error(error));
	}

	// getRankData() {
	// 	if (this.rank > 0 && this.rank == this.scoreProgress) {
	// 	}
	// this.http.get('https://quiz.thinkmad.in/thinkmad/welcome/record_list/1/34').subscribe(data => {
	// 	this.scoreBoard.data = [];
	// 	this.scoreBoard = data;
	// 	console.log("data", data);
	// }, error => console.error(error));
	// }

	// getCurrentData() {
	// this.http.get('https://quiz.thinkmad.in/thinkmad/welcome/record_list/1/' + this.userid).subscribe(data => {
	// 	this.currentUser.data = [];
	// 	this.currentUser = data;
	// 	console.log("data", data);
	// }, error => console.error(error));
	// }

	convertTime() {
		this.totalTime = this.elapsedTime - this.startTime;

		var min = this.totalTime / 1000 / 60;
		var r = min % 1;
		this.sec = Math.floor(r * 60);
		if (this.sec < 10) {
			this.sec = '0' + this.sec;
		}
		min = Math.floor(min);
		this.totalTimeTaken = '00:0' + min + ':' + this.sec;
		return this.totalTimeTaken;
	}

	closeSubmitModal() {
		this.submitModal = false;
		this.router.navigateByUrl('allcategories');
		this.questionTest.reset();
	}
	onClickContinue() {
		this.submitModal = false;
		this.questionTest.reset();
		this.router.navigateByUrl('/set-one/thirdCategories')
	}
	logForm() {
		this.presentAlert();
		this.username = true;
	}

	timeLeft: number = 15;
	interval;

	startTimer() {
		this.interval = setInterval(() => {
			if (this.timeLeft > 0) {
				this.timeLeft--;
				if (this.timeLeft == 0) {
					setTimeout(() => {
						this.goNext();
					}, 2000);
					setTimeout(() => {
						this.stopTimer();
					}, 1000);
				}
			}
		}, 1000);
	}

	stopTimer() {
		clearInterval(this.interval);
		setTimeout(() => {
			this.timeLeft = 15;
			this.startTimer();
		}, 2000);
	}


	async presentAlert() {
		const alert = this.alertController.create({
			cssClass: 'my-custom-class',
			header: 'SignUp SuccessFully Done...!',
			message: 'Now You can See LeaderBoard',
			buttons: ['OK']

		});
		await (await alert).present();

		const { role } = await (await alert).onDidDismiss();
	}
	// getquestions() {
	// 	this.questionManager.question(this.thirdCategories).subscribe((res => {
	// 		console.log("res", res)
	// 		this.allQuestions = res;
	// 	}))

	// }
	// logAccordionValue() {
	// 	console.log(this.accordionGroup.value);
	//   }

	//   closeAccordion() {
	// 	this.accordionGroup.value = undefined;
	//   }  
	toggle_accordion() {
		console.log("called")
		this.accordion_expanded = this.accordion_expanded === false;
	}
}

