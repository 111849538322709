import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AccordionComponent } from 'src/app/components/accordion/accordion.component';
import { SetOnePageRoutingModule } from './set-one-routing.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SetOnePageRoutingModule,
    HttpClientModule,
    NgxDatatableModule
    // HomePageRoutingModule
  ],
  declarations: [
    // SetOnePage,
    // HeaderComponent
    AccordionComponent




  ]
})
export class SetOnePageModule { }
