import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { Config } from "src/config/Config";
import { BaseService } from "../../base.service";


@Injectable()
export class QuizManager extends BaseService {
    insert(quiz: any) {
        return this.postCallService(Config.BASE_URL + Config.POST_QUIZ,{}, quiz).pipe(map(res => {
            if (res) {
                sessionStorage.setItem('currentUser', JSON.stringify(res));
            }

        }))
    }
    find() {
        return this.getCallService(Config.BASE_URL + Config.FIND_QUIZ).pipe(map(res => {
            return res;

        }))
    }
}