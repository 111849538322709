import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },

  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },

  {
    path: 'otp',
    loadChildren: () => import('./otp/otp.module').then(m => m.OtpPageModule)
  },

  {
    path: 'forgot-password',
    loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)
  },

  {
    path: 'user-login',
    loadChildren: () => import('./user-login/user-login.module').then(m => m.UserLoginPageModule)
  },

  {
    path: 'user-register',
    loadChildren: () => import('./user-register/user-register.module').then(m => m.UserRegisterPageModule)
  },

  {
    path: 'field-game',
    loadChildren: () => import('./components/games-module/games/field-game/field-game.module').then(m => m.FieldGamePageModule)
  },

  {
    path: 'game-two',
    loadChildren: () => import('./components/games-module/games/game-two/game-two.module').then(m => m.GameTwoPageModule)
  },

  {
    path: 'set-one',
    loadChildren: () => import('./components/quiz-module/quizlets/set-one/set-one.module').then(m => m.SetOnePageModule)
  },

  {
    path: 'set-two',
    loadChildren: () => import('./components/quiz-module/quizlets/set-two/set-two.module').then(m => m.SetTwoPageModule)
  },

  {
    path: 'set-three',
    loadChildren: () => import('./components/quiz-module/quizlets/set-three/set-three.module').then(m => m.SetThreePageModule)
  },

  {
    path: 'set-four',
    loadChildren: () => import('./components/quiz-module/quizlets/set-four/set-four.module').then(m => m.SetFourPageModule)
  },

  {
    path: 'puzzles',
    loadChildren: () => import('./puzzles/puzzles.module').then(m => m.PuzzlesPageModule)
  },

  {
    path: 'allcategories',
    loadChildren: () => import('./allcategories/allcategories.module').then(m => m.AllcategoriesPageModule)
  },

  {
    path: 'trending-quizzess',
    loadChildren: () => import('./trending-quizzess/trending-quizzess.module').then(m => m.TrendingQuizzessPageModule)
  },

  {
    path: 'celebs',
    loadChildren: () => import('./celebs/celebs.module').then(m => m.CelebsPageModule)
  },
  {
    path: 'exams',
    loadChildren: () => import('./exams/exams.module').then(m => m.ExamsPageModule)
  },
  {
    path: 'aptitude',
    loadChildren: () => import('./aptitude/aptitude.module').then(m => m.AptitudePageModule)
  },
  {
    path: 'general-knowledge',
    loadChildren: () => import('./general-knowledge/general-knowledge.module').then(m => m.GeneralKnowledgePageModule)
  },

  {
    path: 'verbal-reasoning',
    loadChildren: () => import('./verbal-reasoning/verbal-reasoning.module').then(m => m.VerbalReasoningPageModule)
  },

  {
    path: 'currentaffairs',
    loadChildren: () => import('./currentaffairs/currentaffairs.module').then(m => m.CurrentaffairsPageModule)
  },
  {
    path: 'kids-quiz',
    loadChildren: () => import('./kids-quiz/kids-quiz.module').then(m => m.KidsQuizPageModule)
  },

  {
    path: 'engineering',
    loadChildren: () => import('./engineering/engineering.module').then(m => m.EngineeringPageModule)
  },

  {
    path: 'intermediate',
    loadChildren: () => import('./intermediate/intermediate.module').then(m => m.IntermediatePageModule)
  },
  {
    path: 'medical-science',
    loadChildren: () => import('./medical-science/medical-science.module').then(m => m.MedicalSciencePageModule)
  },

  {
    path: 'programming',
    loadChildren: () => import('./programming/programming.module').then(m => m.ProgrammingPageModule)
  },

  {
    path: 'front-end-tech',
    loadChildren: () => import('./front-end-tech/front-end-tech.module').then(m => m.FrontEndTechPageModule)
  },

  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardPageModule)
  },

  {
    path: 'dashcategories',
    loadChildren: () => import('./dashcategories/dashcategories.module').then(m => m.DashcategoriesPageModule)
  },

  {
    path: 'admin-login',
    loadChildren: () => import('./admin-login/admin-login.module').then(m => m.AdminLoginPageModule)
  },

  {
    path: 'admin-register',
    loadChildren: () => import('./admin-register/admin-register.module').then(m => m.AdminRegisterPageModule)
  },

  {
    path: 'accounts',
    loadChildren: () => import('./accounts/accounts.module').then(m => m.AccountsPageModule)
  },

  {
    path: 'verifyemail',
    loadChildren: () => import('./verifyemail/verifyemail.module').then(m => m.VerifyemailPageModule)
  },

  {
    path: 'app-quiz-module',
    loadChildren: () => import('./components/quiz-module/quiz-module.module').then(m => m.QuizModuleModule)
  },
  
  {
    path: '**',
    redirectTo: '/'
  }

];

@NgModule({
  imports: [
    HttpClientModule,
    // RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules },)
    RouterModule.forRoot(routes, { useHash: false, preloadingStrategy: PreloadAllModules }),
    // RouterModule.forRoot(routes, { useHash: true })
    // RouterModule.forRoot(routes)
    // RouterModule.forRoot(routes)
    // RouterModule.forRoot(routes, {  useHash: false, enableTracing: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
