export class Config {
    public static BASE_URL = 'https://api.thinkmad.in/v1/';
    // public static BASE_URL = 'http://localhost:8082/v1/';

    // public static BASE_URL = 'http://64.227.191.34:8082/v1/';
    // public static BASE_URL = 'http://165.232.179.219:8082/v1/';


    public static LOGIN_URL = 'auth/login';

    public static SIGNUP_URL = 'accounts';

    public static GET_CATEGORIES = 'categories';

    public static ADD_CATEGORY = 'categories';

    public static GET_QUESTIONS = 'findallquestions';
    public static FIND_QUESTIONS = 'findQuestions';
    public static FIND_SUB_CATEGORIES = 'findSubCategories';

    public static FINDALL_QUESTIONS = 'findAllquestions';
    public static POST_QUESTIONS = 'findallquestions';
    public static POST_QUIZ = 'quiz';
    public static FIND_QUIZ = 'findquiz';
    API_KEY: 'AIzaSyAQgywJPue27wH93sCoBKP8wte3EqWV-GI'
}