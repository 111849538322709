import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse, HttpParameterCodec } from "@angular/common/http";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { map, catchError } from 'rxjs/operators';
import { CalloutService } from "./callout.service";

import { Router } from "@angular/router";
import { AlertController } from "@ionic/angular";
import { Login } from "./restcontroller/models/login";


@Injectable()
export class BaseService {

    // private objectSource = new BehaviorSubject<Object>(Object);
    // private isLoadIcon = new BehaviorSubject<boolean>(false);
    // private isSideNavShow = new BehaviorSubject<boolean>(false);

    // currentMenuObject = this.objectSource.asObservable();
    // currentLoaderObject = this.isLoadIcon.asObservable();
    // currentSideNavObject = this.isSideNavShow.asObservable();

    public currentUserSubject = new BehaviorSubject<Object>(Object);
    currentUser = this.currentUserSubject.asObservable();

    public static readonly REQUEST_REDIRECT: number = 302;
    public static readonly SC_UNAUTHORIZED: number = 401;
    public static readonly SC_NOT_FOUND: number = 4099;
    public static readonly SC_INTERNAL_SERVER_ERROR: number = 500;
    public static readonly SC_SERVICE_UNAVAILABLE: number = 503;
    public static readonly SC_BAD_REQUEST: number = 400;
    // public static readonly SC_CONFLICT: string = "The question already exist";

    constructor(public router: Router, public httpClient: HttpClient, private calloutService: CalloutService, private alertController: AlertController) {

    }

    ngOnInit(): void {
        const userJson = sessionStorage.getItem('currentUser');
        this.currentUser = userJson !== null ? JSON.parse(userJson) : new Login();
        this.currentUserSubject = new BehaviorSubject<any>(this.currentUser);
        this.currentUser = this.currentUserSubject.asObservable();
    }

    protected postCallService(url: string, parameters: Object = {}, body: Object = {}) {
        // this.dataSharedService.isShowsLoaderIcon(true);
        let httpHeader = new HttpHeaders();
        httpHeader.set('Access-Control-Allow-Origin', "*");
        httpHeader.append('Content-Type', 'application/json');
        return new Observable<any>((observer) => {
            this.httpClient.post(url, this.getRequestBody(body, true), {
                headers: httpHeader,
                params: this.getURLSearchParams(parameters, true),
                responseType: "json"
            }).pipe(map((res: any) => this.extractDataForValidate(res, url)), catchError((e: any) => this.handleError(e, url))).subscribe(res => {
                observer.next(res);
                observer.complete();
            });
        });
    }

    protected postCallService1(url: string, parameters: Object = {}, body: Object = {}) {
        // this.dataSharedService.isShowsLoaderIcon(true);
        let httpHeader = new HttpHeaders();
        httpHeader.set('Access-Control-Allow-Origin', "*");
        httpHeader.append('Content-Type', '');
        return new Observable<any>((observer) => {
            this.httpClient.post(url, this.getRequestBody(body, true), {
                headers: httpHeader,
                params: this.getURLSearchParams(parameters, true),
                responseType: "json"
            }).pipe(map((res: any) => this.extractDataForValidate(res, url)), catchError((e: any) => this.handleError(e, url))).subscribe(res => {
                observer.next(res);
                observer.complete();
            });
        });
    }

    protected putCallService(url: string, parameters: Object = {}, body: Object = {}) {
        // this.dataSharedService.isShowsLoaderIcon(true);
        let httpHeader = new HttpHeaders();
        httpHeader.set('Access-Control-Allow-Origin', "*");
        httpHeader.append('Content-Type', 'application/json');
        return new Observable<any>((observer) => {
            this.httpClient.put(url, this.getRequestBody(body, true), {
                headers: httpHeader,
                params: this.getURLSearchParams(parameters, true),
                responseType: "json"
            }).pipe(map((res: any) => this.extractDataForValidate(res, url)), catchError((e: any) => this.handleError(e, url))).subscribe(res => {
                observer.next(res);
                observer.complete();
            });
        });
    }

    protected getCallService(url: string, parameters: Object = {}) {
        // this.dataSharedService.isShowsLoaderIcon(true);
        let httpHeader = new HttpHeaders();
        httpHeader.set('Access-Control-Allow-Origin', "*");
        httpHeader.append('Content-Type', 'application/json');
        return new Observable<any>((observer) => {
            this.httpClient.get(this.getURLParams(url, parameters), {
                headers: httpHeader,
                responseType: "json"
            }).pipe(map((res: any) => this.extractDataForValidate(res, url)), catchError((e: any) => this.handleError(e, url))).subscribe(res => {
                observer.next(res);
                observer.complete();
            });
        });
    }


    protected deleteCallService(url: string, parameters: Object = {}) {
        // this.dataSharedService.isShowsLoaderIcon(true);
        let httpHeader = new HttpHeaders();
        httpHeader.set('Access-Control-Allow-Origin', "*");
        httpHeader.append('Content-Type', 'application/json');
        return new Observable<any>((observer) => {
            this.httpClient.delete(this.getURLParams(url, parameters), {
                headers: httpHeader,
                responseType: "json"
            }).pipe(map((res: any) => this.extractDataForValidate(res, url)), catchError((e: any) => this.handleError(e, url))).subscribe(res => {
                observer.next(res);
                observer.complete();
            });
        });
    }

    private getURLParams(url: string, parameters: any): string {
        if (parameters) {
            for (let property in parameters) {
                url = url + '/' + parameters[property];
            }
        }
        return url;
    }

    private getURLSearchParams(parametersObj: any, excapeHTML: boolean): HttpParams {
        let parameters = new HttpParams();
        if (parametersObj != null) {
            for (let property in parametersObj) {
                let paramValue = parametersObj[property];
                if (typeof paramValue == "object") {
                    paramValue = excapeHTML ? paramValue : paramValue;
                } else if (typeof paramValue == "boolean") {
                    paramValue = paramValue.toString();
                } else if (typeof paramValue == "number") {
                    paramValue = paramValue.toString();
                }
                parameters = parameters.set(property, paramValue);
            }
        }
        return parameters;
    }

    private getRequestBody(bodyObj: any, excapeHTML: boolean) {
        return bodyObj;
    }

    private extractDataForValidate(res: Response, url: string) {
        // this.dataSharedService.isShowsLoaderIcon(false);
        return res || {};
    }

    private handleError(error: Response | any, url: string) {
        console.log("error base", error);
        this.calloutService.showError(error);
        return throwError(error);
    }
    async presentAlert() {
        const alert = this.alertController.create({
            cssClass: 'my-custom-class',
            header: 'Upload Questions',
            message: 'duplicate Questions',
            buttons: ['OK']

        });
        await (await alert).present();
    }
}