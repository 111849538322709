import { DoCheck, Input } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Renderer2 } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BackendService } from 'src/app/services/backend.service'

@Component({
  selector: 'app-set-three',
  templateUrl: './set-three.page.html',
  styleUrls: ['./set-three.page.scss'],
})
export class SetThreePage implements OnInit {
  username: string;
  userid: any;
  playerName: string;
  gameId = 3;
  isQuestionCardShow: boolean = false;
  @ViewChild('questionTest') questionTest: any;
  userAnswer: any;
  correctAnswer: any;
  submitModal: boolean = false;
  allQuestions: any[];
  scoreBoard: any = {};
  currentUser: any = {};
  @Input() questionIndex: number;
  currentQuestion: string;
  currentOptions: string;
  currentIndex = 0;
  score = 0;
  disableSubmit: boolean = true;
  disableNextBtn: boolean = true;
  counter = 15;
  lap = 0;
  arr: any;
  startTime: number;
  endTime: number;
  totalTime: number;
  totalTimeTaken: any;
  sec: any;
  rightOption: any;
  currentImage: any;
  error: string;
  url: any;
  quizCategory: any;
  scoreColor: any;
  scoreProgress = 0;
  userGlobalId = 0;
  _subscription_user_id: any;

  constructor(
    private router: Router, 
    private renderer: Renderer2, 
    private http: HttpClient,
    private userIdService: BackendService
    ) {
      this._subscription_user_id = this.userIdService.execChange.subscribe((value) => {
      this.userid = value;
    });
    }

  ngOnInit(): void {
    this.username = this.readLocalStorageValue('username');
    this.userid = this.readGuestLocalStorageValue('userid');
    this.allQuestions = [
      {
        question: 'Which space agency along with the European Space Agency has sent a spacecraft on 7-year journey to explore Mercury?',
        options: ['NASA', 'Roscosmos', 'JAXA', 'CNSA'],
        answer: 'Roscosmos'
      },
      {
        question: 'Which country is planning to launch its own artificial moon by 2020?',
        options: ['Russia', 'China', 'US', 'North Korea'],
        answer: 'China'
      },
      {
        question: "Which space probe of NASA will get retired from its service soon?",
        options: ['Juno', 'Project Mercury', 'Hubble', 'Kepler'],
        answer: 'Hubble'
      },
      {
        question: 'Which branch of IIT has developed India’s first microprocessor ‘Shakti’?',
        options: ['IIT Delhi', 'IIT Kharagpur', 'IIT Roorkee','IIT Madras'],
        answer: 'IIT Madras'
      },
      {
        question: "Which state has launched the technology-driven and citizen-friendly ‘Mo bus’ service?",
        options: ['Telangana', 'Karnataka', 'Goa', 'Odisha'],
        answer: 'Karnataka'
      },
      {
        question: 'Which platform selected 20 teams to curb fake news globally, including India?',
        options: ['Facebook', 'Whatsapp', 'Instagram', 'Snapchat'],
        answer: 'Facebook'
      },
      {
        question: "ISRO will be launching which satellite aboard GSLV Mark III on November 14, 2018?",
        options: ['GSAT-29', 'PSAT- 23', 'PSAY- 27', 'DRVS- 35'],
        answer: 'PSAY- 27'
      },
      {
        question: 'According to ISRO Chief K Sivan, the first unmanned programme of "Gaganyaan" has been planned for when?',
        options: ['Dec-20', 'Mar-19', 'January 2019', 'Nov-20'],
        answer: 'Mar-19'
      },
      {
        question: "ISRO launched communication satellite GSAT 29 aboard which launch vehicle?",
        options: ['GSLV Mk III', 'GSLV 2', 'PSLV III', 'ASLV IV'],
        answer: 'GSLV Mk III'
      },
      {
        question: 'India’s first cyber "war room" is going to be set up in which of the following technology institutes?',
        options: ['Indian Institute of Technology, Kharagpur', 'Birla Institute of Technology and Science', 'Bhilai Institute of Technology', 'Bannari Amman Institute of Technology'],
        answer: 'Bhilai Institute of Technology'
      },
      {
        question: 'The International space station turned how old on November 20, 2018?',
        options: ['15 years', '10 years', '20 years', '8 years'],
        answer: '8 years'
      },
      {
        question: 'The first crew of ISS climbed aboard the station in which year?',
        options: ['1999', '1998', '2000', '2001'],
        answer: '1999'
      },
      {
        question: "Which nation’s spacecraft is used by astronauts to travel to and from the International Space Station?",
        options: ['United States', 'Russia', 'United Kingdom', 'Japan'],
        answer: 'United Kingdom'
      },
      {
        question: '‘Zarya’, the first component of the ISS was launched by which space agency?',
        options: ['Roscosmos', 'NASA', 'JAXA', 'ESA'],
        answer: 'JAXA'
      },
      {
        question: "Which state has launched ‘Bhudaar’ portal to make land records accessible to people?",
        options: ['Madhya Pradesh', 'Gujarat', 'Andhra Pradesh', 'Odisha'],
        answer: 'Madhya Pradesh'
      },
      {
        question: '',
        options: ['a', 'b', 'c', 'd'],
        answer: 'c'
      },
    ];
  }

  readLocalStorageValue(key: string): string {
    return localStorage.getItem(key);
  }
  readGuestLocalStorageValue(key: string): string {
    return localStorage.getItem(key);
  }
  ngDoCheck(): void {
    this.currentQuestion = this.allQuestions[this.currentIndex].question;
    this.currentOptions = this.allQuestions[this.currentIndex].options;
  }

  startQuiz() {
    this.quizCategory = "Set Three";
    this.validation();
  }

  validation() {
    if(!this.username) {
      if (this.playerName == null) {
        this.error = "Please enter your name";
      } else {
        this.questionTest.reset();
        this.isQuestionCardShow = true;
        this.startTimer();
        this.startTime = performance.now();
      }
    } else {
      this.playerName = this.username;
      this.questionTest.reset();
      this.isQuestionCardShow = true;
      this.startTimer();
      this.startTime = performance.now();
    }    
  }

  checkprogress() {
    this.scoreProgress = this.score / (this.allQuestions.length-1);
  }

  setUserAnswer(option: string) {
    this.userAnswer = option;
    this.correctAnswer = this.allQuestions[this.currentIndex].answer;

    this.arr = document.getElementsByClassName('answers');
    for (let i = 0; i < this.arr.length; i++) {
      if (this.arr[i].value == this.correctAnswer) {
        this.rightOption = this.arr[i];
      }
    }

    if (this.userAnswer === this.correctAnswer) {
      this.renderer.addClass(event.target, "rightAns");
      this.score++;
      this.playRightAudio();

      for (let i = 0; i < this.arr.length; i++) {
        this.arr[i].disabled = true;
      }
    } else {
      this.renderer.addClass(event.target, "wrongAns");
      this.renderer.addClass(this.rightOption, "rightAns");
      this.playWrongAudio();

      for (let i = 0; i < this.arr.length; i++) {
        this.arr[i].disabled = true;
      }
    }
    this.stopTimer();

    setTimeout(() => {
      this.goNext();
    }, 2000);
  }

  goNext() {
    this.currentIndex++;
    if (this.currentIndex === this.allQuestions.length - 1) {
      this.endQuiz();
    }
  }

  volumeIcon: string = 'volume-high';
  muteSound() {
    this.volumeIcon = this.volumeIcon === 'volume-high' ? 'volume-mute' : 'volume-high';
  }

  playRightAudio() {
    let audio = new Audio();
    audio.src = "../../../assets/audio/Correct-answer.mp3";
    audio.load();
    audio.play();
    if(this.volumeIcon === 'volume-mute') {
      audio.muted = true;
    }
  }

  playWrongAudio() {
    let audio = new Audio();
    audio.src = "../../../assets/audio/Wrong-answer.mp3";
    audio.load();
    audio.play();
    if(this.volumeIcon === 'volume-mute') {
      audio.muted = true;
    }
  }

  endQuiz() {
    clearInterval(this.interval);
    this.endTime = performance.now();
    this.convertTime();
    this.checkprogress();
    this.storeUserData();
    setTimeout(() => {
      this.getRankData();
      this.getCurrentData();
     
    }, 2000); 
    this.isQuestionCardShow = false;
    this.submitModal = true; 
  }

  storeUserData() {
    var percentage = (this.score/this.allQuestions.length)*100;
    const formData = new FormData();
    formData.append('playerName', this.playerName);
    formData.append('score', this.score.toString());
    formData.append('endTime', this.totalTimeTaken.toString());
    formData.append('scorePercentage', percentage.toString());
    formData.append('game_id', this.gameId.toString());

    this.http.post<any>("https://quiz.thinkmad.in/thinkmad/welcome/insert_record/", formData).subscribe( data => {
      localStorage.setItem('userid', data.data.user_id);
      this.userIdService.userIdChange(data.data.user_id);
    });
  }

  getRankData() {
    this.http.get('https://quiz.thinkmad.in/thinkmad/welcome/record_list/3/1/').subscribe(data => {
      this.scoreBoard.data = [];
      this.scoreBoard = data;
    }, error => console.error(error));
  }
 
  getCurrentData() {
    this.http.get('https://quiz.thinkmad.in/thinkmad/welcome/record_list/3/'+this.userid).subscribe(data => {
      this.currentUser.data = [];
      this.currentUser = data;
    }, error => console.error(error));
  }

  convertTime() {
    this.totalTime = this.endTime - this.startTime;

    var min = this.totalTime / 1000 / 60;
    var r = min % 1;
    this.sec = Math.floor(r * 60);
    if (this.sec < 10) {
      this.sec = '0' + this.sec;
    }
    min = Math.floor(min);
    this.totalTimeTaken = '00:0' + min + ':' + this.sec;
    return this.totalTimeTaken;
  }

  closeSubmitModal() {
    this.submitModal = false;
    this.router.navigateByUrl('home');
    this.questionTest.reset();
  }

  timeLeft: number = 15;
  interval;

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
        if (this.timeLeft == 0) {
          setTimeout(() => {
            this.goNext();
          }, 2000);
          setTimeout(() => {
            this.stopTimer();
          }, 1000);
        }
      }
    }, 1000);
  }

  stopTimer() {
    clearInterval(this.interval);
    setTimeout(() => {
      this.timeLeft = 15;
      this.startTimer();
    }, 2000);
  }
}
