import { DoCheck, Input } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Renderer2 } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BackendService } from 'src/app/services/backend.service'

@Component({
  selector: 'app-set-four',
  templateUrl: './set-four.page.html',
  styleUrls: ['./set-four.page.scss'],
})
export class SetFourPage implements OnInit {
  username: string;
  userid: any;
  playerName: string;
  gameId = 4;
  isQuestionCardShow: boolean = false;
  @ViewChild('questionTest') questionTest: any;
  userAnswer: any;
  correctAnswer: any;
  submitModal: boolean = false;
  allQuestions: any[];
  scoreBoard: any = {};
  currentUser: any = {};
  @Input() questionIndex: number;
  currentQuestion: string;
  questionImage: any;
  currentOptions: string;
  currentDirections: any;
  currentIndex = 0;
  score = 0;
  disableSubmit: boolean = true;
  disableNextBtn: boolean = true;
  counter = 15;
  lap = 0;
  arr: any;
  startTime: number;
  endTime: number;
  totalTime: number;
  totalTimeTaken: any;
  sec: any;
  rightOption: any;
  currentImage: any;
  error: string;
  url: any;
  quizCategory: any;
  scoreColor: any;
  scoreProgress = 0;
  userGlobalId = 0;
  _subscription_user_id: any;

  constructor(
    private router: Router, 
    private renderer: Renderer2, 
    private http: HttpClient,
    private userIdService: BackendService
    ) {
      this._subscription_user_id = this.userIdService.execChange.subscribe((value) => {
      this.userid = value;
    });
    }

  ngOnInit(): void {
    this.username = this.readLocalStorageValue('username');
    this.userid = this.readGuestLocalStorageValue('userid');
    this.allQuestions = [
      {
        directions: 'Study the following table carefully and answer the questions given below:',
        question: 'The number of tickets of T2 sold in Bengaluru is approximately what percentage of the total number of tickets of the same tournament sold in all the cities together?',
        image: './assets/img/Question1.jpg',
        options: ['18%', '12%', '20%', '24%'],
        answer: '18%'
      },
      {
        directions: 'Study the following table carefully and answer the questions given below:',
        question: 'What is the difference between the number of tickets of T2 sold in Mohali and the number of tickets of T4 sold in Kolkata?',
        image: './assets/img/Question2.jpg',
        options: ['7000', '14000', '6000', '60000'],
        answer: '7000'
      },
      {
        directions: 'Study the following table carefully and answer the questions given below:',
        question: "What is the average number of tickets of T3 sold in all the six cities?",
        image: './assets/img/Question3.jpg',
        options: ['15500', '2550', '24000', '25500'],
        answer: '2550'
      },
      {
        directions: 'Study the following table carefully and answer the questions given below:',
        question: 'The number of tickets of T5 sold in Chennai is what percentage of number of tickets of T1 sold in Mumbai?',
        image: './assets/img/Question4.jpg',
        options: ['170%', '70%', '130%','30%'],
        answer: '30%'
      },
      {
        directions: 'The bar graph provided below gives the sales of Music CD’s (in thousand numbers) from six stores of a Music company during two consecutive years 2012 and 2013. Answer the questions based on the graph.',
        question: "What is the ratio of annual sales of store C and E in 2012 and annual sales store C and F in 2013?",
        image: './assets/img/Question5.jpg',
        options: ['18:17', '17:19', '19:21', '21:13'],
        answer: '17:19'
      },
      {
        directions: 'The bar graph provided below gives the sales of Music CD’s (in thousand numbers) from six stores of a Music company during two consecutive years 2012 and 2013. Answer the questions based on the graph.',
        question: 'Total sales of stores A, C and E together for both the years (in thousands) is:',
        image: './assets/img/Question6.jpg',
        options: ['586', '560', '480', 'Data Inadequate'],
        answer: 'Data Inadequate'
      },
      {
        directions: 'The bar graph provided below gives the sales of Music CD’s (in thousand numbers) from six stores of a Music company during two consecutive years 2012 and 2013. Answer the questions based on the graph.',
        question: "Total sales of store F for both the years are what percent of the total sales of the store C for both the years?",
        image: './assets/img/Question7.jpg',
        options: ['64.04%', '81.12%', '73.17%', '71.71%'],
        answer: '81.12%'
      },
      {
        directions: 'The bar graph provided below gives the sales of Music CD’s (in thousand numbers) from six stores of a Music company during two consecutive years 2012 and 2013. Answer the questions based on the graph.',
        question: 'Find the average sales (in thousands) of all the stores in the year 2012.',
        image: './assets/img/Question8.jpg',
        options: ['76', '85', '92', '80'],
        answer: '76'
      },
      {
        directions: 'The bar graph provided below gives the sales of Music CD’s (in thousand numbers) from six stores of a Music company during two consecutive years 2012 and 2013. Answer the questions based on the graph.',
        question: "What percent of average sales of stores A, B and C in 2013 is the average sales of the stores A, C and F in 2012?",
        image: './assets/img/Question9.jpg',
        options: ['91.10%', '82.60%', '114.20%', '87.50%'],
        answer: '114.20%'
      },
      {
        directions: 'Study the following graph and pie chart carefully to answer the questions given below:',
        question: "Total number of students in School R is approximately what per cent of total number of students in School S?",
        image: './assets/img/Question10.jpg',
        options: ['147', '279', '312', '207'],
        answer: '207'
      },
      {
        directions: 'Study the following graph and pie chart carefully to answer the questions given below:',
        question: 'What is the total number of boys in all the Schools together?',
        image: './assets/img/Question11.jpg',
        options: ['13350', '14700', '15715', '16280'],
        answer: '14700'
      },
      {
        directions: 'Study the following graph and pie chart carefully to answer the questions given below:',
        question: 'What is the total number of boys in Schools P and R together?',
        image: './assets/img/Question12.jpg',
        options: ['6125', '8400', '8025', '7400'],
        answer: '7400'
      },
      {
        directions: 'Study the following graph and pie chart carefully to answer the questions given below:',
        question: "What is the difference between the number of girls in School Q and the number of girls in School T?",
        image: './assets/img/Question13.jpg',
        options: ['455', '350', '170', '300'],
        answer: '300'
      },
      {
        directions: 'Study the following graph and pie chart carefully to answer the questions given below:',
        question: 'Total number of girls in School Q and T is approximately what percentage of total number of boys in Q and R together?',
        image: './assets/img/Question14.jpg',
        options: ['75%', '127%', '120%', '68.5%'],
        answer: '75%'
      },
      {
        directions: 'The pie chart given below shows the expenditure on various cost centres for the production and distribution of a product line X during a particular year. Answer the questions given below after studying the graph carefully.',
        question: "Calculate the percentage of the total cost is part of distribution cost?",
        image: './assets/img/Question15.jpg',
        options: ['1212%', '2212%', '25%', '45%'],
        answer: '25%'
      },
      {
        directions: '',
        question: '',
        image: '',
        options: ['a', 'b', 'c', 'd'],
        answer: 'c'
      },
    ];
  }

  readLocalStorageValue(key: string): string {
    return localStorage.getItem(key);
  }
  readGuestLocalStorageValue(key: string): string {
    return localStorage.getItem(key);
  }
  ngDoCheck(): void {
    this.currentQuestion = this.allQuestions[this.currentIndex].question;
    this.questionImage = this.allQuestions[this.currentIndex].image;
    this.currentDirections = this.allQuestions[this.currentIndex].directions;
    this.currentOptions = this.allQuestions[this.currentIndex].options;
  }

  startQuiz() {
    this.quizCategory = "Set Four";
    this.validation();
  }

  validation() {
    if(!this.username) {
      if (this.playerName == null) {
        this.error = "Please enter your name";
      } else {
        this.questionTest.reset();
        this.isQuestionCardShow = true;
        this.startTimer();
        this.startTime = performance.now();
      }
    } else {
      this.playerName = this.username;
      this.questionTest.reset();
      this.isQuestionCardShow = true;
      this.startTimer();
      this.startTime = performance.now();
    }    
  }

  checkprogress() {
    this.scoreProgress = this.score / (this.allQuestions.length-1);
  }

  setUserAnswer(option: string) {
    this.userAnswer = option;
    this.correctAnswer = this.allQuestions[this.currentIndex].answer;

    this.arr = document.getElementsByClassName('answers');
    for (let i = 0; i < this.arr.length; i++) {
      if (this.arr[i].value == this.correctAnswer) {
        this.rightOption = this.arr[i];
      }
    }

    if (this.userAnswer === this.correctAnswer) {
      this.renderer.addClass(event.target, "rightAns");
      this.score++;
      this.playRightAudio();

      for (let i = 0; i < this.arr.length; i++) {
        this.arr[i].disabled = true;
      }
    } else {
      this.renderer.addClass(event.target, "wrongAns");
      this.renderer.addClass(this.rightOption, "rightAns");
      this.playWrongAudio();

      for (let i = 0; i < this.arr.length; i++) {
        this.arr[i].disabled = true;
      }
    }
    this.stopTimer();

    setTimeout(() => {
      this.goNext();
    }, 2000);
  }

  goNext() {
    this.currentIndex++;
    if (this.currentIndex === this.allQuestions.length - 1) {
      this.endQuiz();
    }
  }

  volumeIcon: string = 'volume-high';
  muteSound() {
    this.volumeIcon = this.volumeIcon === 'volume-high' ? 'volume-mute' : 'volume-high';
  }

  playRightAudio() {
    let audio = new Audio();
    audio.src = "../../../assets/audio/Correct-answer.mp3";
    audio.load();
    audio.play();
    if(this.volumeIcon === 'volume-mute') {
      audio.muted = true;
    }
  }

  playWrongAudio() {
    let audio = new Audio();
    audio.src = "../../../assets/audio/Wrong-answer.mp3";
    audio.load();
    audio.play();
    if(this.volumeIcon === 'volume-mute') {
      audio.muted = true;
    }
  }

  endQuiz() {
    clearInterval(this.interval);
    this.endTime = performance.now();
    this.convertTime();
    this.checkprogress();
    this.storeUserData();
    setTimeout(() => {
      this.getRankData();
      this.getCurrentData();
     
    }, 2000); 
    this.isQuestionCardShow = false;
    this.submitModal = true; 
  }

  storeUserData() {
    var percentage = (this.score/this.allQuestions.length)*100;
    const formData = new FormData();
    formData.append('playerName', this.playerName);
    formData.append('score', this.score.toString());
    formData.append('endTime', this.totalTimeTaken.toString());
    formData.append('scorePercentage', percentage.toString());
    formData.append('game_id', this.gameId.toString());

    this.http.post<any>("https://quiz.thinkmad.in/thinkmad/welcome/insert_record", formData).subscribe( data => {
      localStorage.setItem('userid', data.data.user_id);
      this.userIdService.userIdChange(data.data.user_id);
    });
  }

  getRankData() {
    this.http.get('https://quiz.thinkmad.in/thinkmad/welcome/record_list/4/34').subscribe(data => {
      this.scoreBoard.data = [];
      this.scoreBoard = data;
    }, error => console.error(error));
  }
 
  getCurrentData() {
    this.http.get('https://quiz.thinkmad.in/thinkmad/welcome/record_list/4/'+this.userid).subscribe(data => {
      this.currentUser.data = [];
      this.currentUser = data;
    }, error => console.error(error));
  }

  convertTime() {
    this.totalTime = this.endTime - this.startTime;

    var min = this.totalTime / 1000 / 60;
    var r = min % 1;
    this.sec = Math.floor(r * 60);
    if (this.sec < 10) {
      this.sec = '0' + this.sec;
    }
    min = Math.floor(min);
    this.totalTimeTaken = '00:0' + min + ':' + this.sec;
    return this.totalTimeTaken;
  }

  closeSubmitModal() {
    this.submitModal = false;
    this.router.navigateByUrl('home');
    this.questionTest.reset();
  }

  timeLeft: number = 15;
  interval;

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
        if (this.timeLeft == 0) {
          setTimeout(() => {
            this.goNext();
          }, 2000);
          setTimeout(() => {
            this.stopTimer();
          }, 1000);
        }
      }
    }, 1000);
  }

  stopTimer() {
    clearInterval(this.interval);
    setTimeout(() => {
      this.timeLeft = 15;
      this.startTimer();
    }, 2000);
  }
}
